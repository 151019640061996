<template>
  <div class="card">
    <div class="card-body">
      <div class="d-flex justify-content-between text-center indicator-text">
        <div>
          <h3 :style="{ color: color }">
            {{ pastValue.toLocaleString("de-DE") }}
          </h3>
          <span>12 MONTHS AGO</span>
        </div>
        <div>
          <h3 :style="{ color: color }">
            {{ currentValue.toLocaleString("de-DE") }}
          </h3>
          <span>NOW</span>
        </div>
        <div>
          <h3 :style="{ color: color }">{{ percentageGrowth }}%</h3>
          <span>GROWTH</span>
        </div>
      </div>
      <div class="description-container">
        <h3 class="text-center" :style="{ color: color }">{{ description }}</h3>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "PercentageGrowth",
  props: {
    currentValue: {
      type: Number,
      default: 0,
    },
    description: {
      type: String,
      required: true,
    },
    pastValue: {
      type: Number,
      default: 0,
    },
    color: {
      type: String,
      default: "#59CFA6",
    },
  },
  computed: {
    percentageGrowth: function () {
      if (this.currentValue > this.pastValue) {
        return this.pastValue === 0
          ? Math.round((this.currentValue - this.pastValue) * 100)
          : Math.round(
              ((this.currentValue - this.pastValue) / this.pastValue) * 100
            );
      } else {
        return this.pastValue === 0
          ? Math.round((this.pastValue - this.currentValue) * 100)
          : Math.round(
              ((this.pastValue - this.currentValue) / this.pastValue) * 100
            );
      }
    },
  },
};
</script>

<style scoped>
.description-container {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}
.indicator-text {
  color: #81a6b7;
  border-bottom: 2px solid #f6f7f8;
  padding: 1rem;
}
</style>
