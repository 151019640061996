<template>
  <el-row class="mb-10" :gutter="20">
    <el-col
      :span="12"
      v-for="(data, index) in percentageGrowthData"
      :key="index"
    >
      <PercentageGrowth
        :current-value="data.presentFollowers"
        :description="data.description"
        :past-value="data.pastFollowers"
        :color="data.color"
      />
    </el-col>
  </el-row>
  <CardToolbar
    description="Development of Fanbase and Listeners over last 12 months"
    title="Fanbase & Listeners"
    :show-toolbar-buttons="false"
  >
    <template v-slot:body>
      <el-skeleton :loading="loading" animated>
        <template #default>
          <RevenueChart
            :currency-values="false"
            :colors="['#59CFA6', '#A373E9']"
            :categories="fanbaseChartCategories"
            :series="fanbaseChartSeries"
          />
        </template>
      </el-skeleton>
    </template>
  </CardToolbar>
</template>

<script>
import { onMounted, ref } from "vue";
import { useRoute } from "vue-router";

import CardToolbar from "../../common/components/CardToolbar";
import { getArtistAnalytics, getFanbaseOvertime } from "../../api/artists.api";
import RevenueChart from "../../common/components/RevenueChart";
import { platforms } from "../../assets/static/platforms";
import PercentageGrowth from "../components/PercentageGrowth";

export default {
  components: { CardToolbar, RevenueChart, PercentageGrowth },
  name: "Analytics",
  setup() {
    const fanbaseChartSeries = ref([]);
    const fanbaseChartCategories = ref([]);
    const loading = ref(true);
    const percentageGrowthData = ref([]);
    const route = useRoute();

    onMounted(async () => {
      try {
        const uuid = route.params.uuid;
        loading.value = true;
        await getSpotifyData(uuid);
        await getFanbaseData(uuid);
      } catch (e) {
        console.error(e);
      } finally {
        loading.value = false;
      }
    });

    const appendToGraphData = (dataFromApi, totalFanbase) => {
      if (totalFanbase.length) {
        totalFanbase = dataFromApi.map(
          (value, index) => value + totalFanbase[index]
        );
      } else {
        totalFanbase = [...dataFromApi];
      }
      return totalFanbase;
    };

    const getFanbaseData = async (uuid) => {
      let totalFanbase = [];
      let totalFanbasePast = 0;
      let totalFanbasePresent = 0;
      let promises = [];
      Object.values(platforms).forEach((platform) => {
        promises.push(
          getFanbaseOvertime(uuid, platform.name).then((resp) => {
            totalFanbasePast += resp.data.yAxis[0];
            totalFanbasePresent += resp.data.yAxis[resp.data.yAxis.length - 1];
            totalFanbase = appendToGraphData(resp.data.yAxis, totalFanbase);
            if (!fanbaseChartCategories.value.length) {
              fanbaseChartCategories.value = [...resp.data.xAxis];
            }
          })
        );
      });
      await Promise.all(promises);
      percentageGrowthData.value.push({
        pastFollowers: totalFanbasePast,
        presentFollowers: totalFanbasePresent,
        description: "Total Fanbase",
        color: "#A373E9",
      });
      fanbaseChartSeries.value.push({
        data: [...totalFanbase],
        type: "line",
        name: "Total Fanbase",
      });
    };

    const getSpotifyData = async (uuid) => {
      const { data } = await getArtistAnalytics(
        uuid,
        12,
        true,
        "spotify/listeners"
      );
      const totalListenersPast = data.yAxis[0];
      const totalListenersPresent = data.yAxis[data.yAxis.length - 1];
      fanbaseChartSeries.value.push({
        data: [...data.yAxis],
        type: "line",
        name: "Spotify Monthly Listeners",
      });
      percentageGrowthData.value.push({
        pastFollowers: totalListenersPast,
        presentFollowers: totalListenersPresent,
        description: "Spotify Monthly Listeners",
        color: "#59CFA6",
      });
    };

    return {
      fanbaseChartCategories,
      fanbaseChartSeries,
      loading,
      percentageGrowthData,
    };
  },
};
</script>

<style scoped></style>
